<template>
  <span>
    <status-indicator
      v-if="selectedChargerStatus === 'Available'"
      v-b-tooltip.hover
      title="Available"
      style="cursor: default"
      status="positive"
      pulse
    />
    <template
      v-if="
        meterValue !== undefined &&
        meterValue !== null &&
        meterValue.CurrentImportInlet !== undefined &&
        meterValue.PowerActiveImport !== undefined
      "
    >
      <status-indicator
        v-if="
          selectedChargerStatus === 'Charging' &&
          (meterValue.CurrentImportInlet.value > null ||
            meterValue.PowerActiveImport.value > 0)
        "
        v-b-tooltip.hover
        title="Charging"
        style="cursor: default"
        status="active"
        pulse
      />
      <status-indicator
        v-else-if="
          selectedChargerStatus === 'Charging' &&
          (meterValue.CurrentImportInlet.value < 0 ||
            meterValue.PowerActiveImport.value < 0)
        "
        v-b-tooltip.hover
        title="Discharging"
        style="cursor: default"
        status="active"
        pulse
      />
    </template>
    <template v-else>
      <status-indicator
        v-if="selectedChargerStatus === 'Charging'"
        v-b-tooltip.hover
        title="Charging"
        style="cursor: default"
        status="active"
        pulse
      />
    </template>
    <status-indicator
      v-if="selectedChargerStatus === 'NotCharging'"
      v-b-tooltip.hover
      title="Not Charging"
      style="cursor: default"
      status="positive"
      pulse
    />
    <status-indicator
      v-if="selectedChargerStatus === 'Unavailable'"
      v-b-tooltip.hover
      title="Unavailable"
      style="cursor: default"
      status="positive-transparent"
    />
    <status-indicator
      v-if="selectedChargerStatus === 'Faulted'"
      v-b-tooltip.hover
      title="Faulted"
      style="cursor: default"
      status="negative"
      pulse
    />
    <status-indicator
      v-if="selectedChargerStatus === 'Occupied'"
      v-b-tooltip.hover
      title="Occupied"
      style="cursor: default"
      status="positive"
      pulse
    />
    <status-indicator
      v-if="selectedChargerStatus === 'Disconnected'"
      v-b-tooltip.hover
      title="Disconnected"
      style="cursor: default"
      status=""
    />
  </span>
</template>

<script>
import { StatusIndicator } from "vue-status-indicator";

export default {
  name: "ChargerStatus",
  props: ["chargerId", "meterValue"],
  components: {
    StatusIndicator,
  },
  computed: {
    selectedChargerStatus() {
      const chargerStatus = this.$store.getters.getChargerStatusByChargerId(
        this.chargerId
      );
      if (chargerStatus === undefined || chargerStatus.connected === false) {
        return "Disconnected";
      }
      const statuses = chargerStatus.connectorStatuses.map(
        (connectorStatus) => connectorStatus.status
      );
      const notChargingStatusValues = [
        "Finishing",
        "Preparing",
        "SuspendedEV",
        "SuspendedEVSE",
      ];
      const notCharging = statuses.some(
        (status) => notChargingStatusValues.indexOf(status) >= 0
      );
      if (statuses.includes("Faulted")) {
        return "Faulted";
      } else if (statuses.includes("Charging")) {
        return "Charging";
      } else if (notCharging) {
        return "NotCharging";
      } else if (statuses.includes("Occupied")) {
        return "Occupied";
      } else if (statuses.includes("Available")) {
        return "Available";
      } else {
        return "Disconnected";
      }
    },
  },
};
</script>
