<template>
  <div>
    <template v-if="rawMeterValues && rawMeterValues.length !== 0">
      <template v-for="(value, index) in transformMeterValues()">
        <div
          :key="index + '-connector'"
          v-if="
            connectorId === undefined ||
            value.connectorId.toString() === connectorId
          "
        >
          <span
            v-if="value.hasOwnProperty('phase') && connectorId === undefined"
            v-b-tooltip.hover
            title="Connector Id,Phase"
          >
            {{ `${value.connectorId},${value.phase}:` }}
          </span>
          <span
            v-else-if="connectorId === undefined"
            v-b-tooltip.hover
            title="Connector ID"
          >
            {{ `${value.connectorId}:` }}
          </span>
          <span
            v-else-if="value.hasOwnProperty('phase')"
            v-b-tooltip.hover
            title="Phase"
          >
            {{ `${value.phase}:` }}
          </span>
          <template
            class="w-50"
            v-for="(meter, key, index) in value.meterValues"
          >
            <span
              :key="index + '-meterValue'"
              v-if="meter.value !== undefined && meter.value !== null"
              v-bind:class="{
                'text-danger': key.includes('Export'),
                'text-success': key.includes('Import'),
                'text-secondary': key,
              }"
              v-b-tooltip.hover.topleft
              :title="key === 'SoC' ? 'SoC' : key.split(/(?=[A-Z])/).join(' ')"
            >
              <span>
                {{
                  `${meter.value} ${
                    meter.unit
                      ? meter.unit === "Percent"
                        ? "%"
                        : meter.unit === "Celsius"
                        ? "°C"
                        : meter.unit === "Fahrenheit"
                        ? "°F"
                        : meter.unit
                      : ""
                  }`
                }}
              </span>
              <span
                class="text-dark"
                v-if="index !== Object.keys(value.meterValues).length - 1"
                >{{ " /" }}</span
              >
            </span>
            <span v-else :key="index + '-meterUnit'">
              {{ `-/ ${meter.unit ? meter.unit : ""}` }}
            </span>
            <time-ago
              :key="index + '-time-ago'"
              v-if="index === Object.keys(value.meterValues).length - 1"
              class="timeAgo"
              :refresh="60"
              :long="false"
              :tooltip="true"
              :datetime="meter.timestamp"
            />
          </template>
        </div>
      </template>
    </template>
    <span v-else> No Meter Value</span>
  </div>
</template>

<script>
import TimeAgo from "vue2-timeago";

export default {
  name: "SelectedMeterValue",
  props: ["chargerId", "connectorId", "rawMeterValues"],
  components: { TimeAgo },
  methods: {
    transformMeterValues() {
      return this.rawMeterValues?.map((meterValue) => {
        let meterValueCopy = Object.assign({}, meterValue);
        for (const key of Object.keys(meterValue.meterValues)) {
          if (
            key === "EnergyActiveImportRegisterOutlet" ||
            key === "EnergyActiveImportRegisterInlet"
          ) {
            const newCopy = Object.assign({}, meterValueCopy.meterValues[key]);
            newCopy.value /= 1000;
            newCopy.unit = "kWh";

            meterValueCopy = Object.assign(meterValueCopy, {
              meterValues: { ...meterValueCopy.meterValues, [key]: newCopy },
            });
          }
        }
        return meterValueCopy;
      });
    },
  },
};
</script>

<style>
.timeAgo span {
  padding-left: 12px;
  font-size: 0.7rem;
  color: lightslategray;
  font-style: normal;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}
</style>
