var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.rawMeterValues && _vm.rawMeterValues.length !== 0)?[_vm._l((_vm.transformMeterValues()),function(value,index){return [(
          _vm.connectorId === undefined ||
          value.connectorId.toString() === _vm.connectorId
        )?_c('div',{key:index + '-connector'},[(value.hasOwnProperty('phase') && _vm.connectorId === undefined)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Connector Id,Phase"}},[_vm._v(" "+_vm._s(((value.connectorId) + "," + (value.phase) + ":"))+" ")]):(_vm.connectorId === undefined)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Connector ID"}},[_vm._v(" "+_vm._s(((value.connectorId) + ":"))+" ")]):(value.hasOwnProperty('phase'))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Phase"}},[_vm._v(" "+_vm._s(((value.phase) + ":"))+" ")]):_vm._e(),_vm._l((value.meterValues),function(meter,key,index){return [(meter.value !== undefined && meter.value !== null)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",modifiers:{"hover":true,"topleft":true}}],key:index + '-meterValue',class:{
              'text-danger': key.includes('Export'),
              'text-success': key.includes('Import'),
              'text-secondary': key,
            },attrs:{"title":key === 'SoC' ? 'SoC' : key.split(/(?=[A-Z])/).join(' ')}},[_c('span',[_vm._v(" "+_vm._s(((meter.value) + " " + (meter.unit ? meter.unit === "Percent" ? "%" : meter.unit === "Celsius" ? "°C" : meter.unit === "Fahrenheit" ? "°F" : meter.unit : "")))+" ")]),(index !== Object.keys(value.meterValues).length - 1)?_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(" /"))]):_vm._e()]):_c('span',{key:index + '-meterUnit'},[_vm._v(" "+_vm._s(("-/ " + (meter.unit ? meter.unit : "")))+" ")]),(index === Object.keys(value.meterValues).length - 1)?_c('time-ago',{key:index + '-time-ago',staticClass:"timeAgo",attrs:{"refresh":60,"long":false,"tooltip":true,"datetime":meter.timestamp}}):_vm._e()]})],2):_vm._e()]})]:_c('span',[_vm._v(" No Meter Value")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }