import { OCPP_API } from "./common/http-common";

const tenant = process.env.VUE_APP_TENANT;

export function getMeterValue() {
  return OCPP_API.get(`/${tenant}/chargepoints/meters`);
}

export function getMeterValueForCharger(chargerId) {
  return OCPP_API.get(`/${tenant}/chargepoints/${chargerId}/meters`);
}
